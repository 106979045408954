import { BASE_URL } from './const';
import axios from 'axios';

// SAMPLE CODE FOR FETCH PARAMS
// ====================================
// const response = await fetch(url, {
//     method: "POST", // *GET, POST, PUT, DELETE, etc.
//     mode: "cors", // no-cors, *cors, same-origin
//     cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
//     credentials: "same-origin", // include, *same-origin, omit
//     headers: {
//       "Content-Type": "application/json",
//       // 'Content-Type': 'application/x-www-form-urlencoded',
//     },
//     redirect: "follow", // manual, *follow, error
//     referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
//     body: JSON.stringify(data), // body data type must match "Content-Type" header
//   });
//   return response.json(); // parses JSON response into native JavaScript objects
// }

function constructURL(baseURL, paramsObject) {
  if (!paramsObject) {
    return baseURL;
  }

  // Ensure baseURL ends with a '?'
  if (!baseURL.endsWith('?') && !baseURL.endsWith('&')) {
    baseURL += '?';
  }

  // Serialize paramsObject into query parameters
  const queryString = Object.keys(paramsObject)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(paramsObject[key])}`)
    .join('&');

  // Concatenate baseURL with queryString
  return baseURL + queryString;
}

const supportedGetTypes = ['JSON', 'TEXT'];

export async function callAPI(
  endpoint,
  httpMethod,
  jsonBody,
  APIResponseFrom
) {
  // BASE API URL
  let URL = `${BASE_URL}/${endpoint}`;

  // check if the APIResponseFrom is present inside the supportedGetTypes
  if (!supportedGetTypes.includes(APIResponseFrom)) {
    throw new Error('API Fetch Failed, we are only supporting JSON or TEXT Fetch currently');
  }

  let headers = {}

  let requestOptions = {
    method: httpMethod,
    url: URL,
    headers: headers,
    withCredentials: true, // Include cookies in the request
  };

  if (httpMethod === 'POST') {
    requestOptions.data = jsonBody;
  } else if (httpMethod === 'GET' && jsonBody) {
    URL = constructURL(URL, jsonBody);
    requestOptions.url = URL;
  }

  return await axios(requestOptions)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export async function loginAPI (endpoint, httpMethod, jsonBody, responseType = 'JSON') {
  let URL = `${BASE_URL}/${endpoint}`;
  let headers = {
    'Content-Type': 'application/x-www-form-urlencoded'
  }

  const urlencoded = new URLSearchParams();
  urlencoded.append('username', jsonBody.username);
  urlencoded.append('password', jsonBody.password);

  let requestOptions = {
    method: httpMethod,
    url: URL,
    headers: headers,
    data: urlencoded,
    withCredentials: true,
  };

  return await axios(URL, requestOptions)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};
