import React, { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';

export const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [firstname, setFirstname] = useState(localStorage.getItem('firstname'));
  const [email, setEmail] = useState(localStorage.getItem('email'));

  const saveLoginData = (data) => {
    localStorage.setItem('firstname', data.first_name);
    localStorage.setItem('email', data.email);
    setFirstname(data.first_name);
    setEmail(data.email);
  };

  const logout = () => {
    cleanLocalStorage();
    localStorage.removeItem('mainJson');
  };

  const cleanLocalStorage = () => {
    localStorage.removeItem('firstname');
    localStorage.removeItem('email');
    setFirstname(null);
    setEmail(null);
  }

  return (
    <AuthContext.Provider value={{ firstname, email, saveLoginData, logout, cleanLocalStorage }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
