import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/authContext';

export function useSessionManager() {
    const navigate = useNavigate();
    const { logout, cleanLocalStorage } = useAuth();

    const handleSessionExpiration = useCallback((location, serializedData, autoLogout=true) => {
        if (serializedData) {
            localStorage.setItem('mainJson', JSON.stringify(serializedData));
        }
    
        if (autoLogout) {
            cleanLocalStorage();
        } else {
            logout();
        }
        
        navigate('/signin', { state: { from: location } });
        
    }, []);

    return { handleSessionExpiration };
}
