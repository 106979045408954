import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Container, TextField, Typography, Link, Snackbar, Alert } from '@mui/material';
import { Box } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import { imageUrls } from '../utils/const';
import { SignInButton } from '../css/muiStyles';
import { SignInModel } from '../model/AuthModel';
import { loginAPI } from '../utils/ApiUtils';
import { useAuth } from '../contexts/authContext';
import { nasaLogo } from '../css/authStyles';

const SignIn = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { saveLoginData } = useAuth();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [infoSnackbar, setInfoSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('info');
  const [credentials, setCredentials] = useState({
    email: '',
    password: '',
  });
  const { from } = location.state || { from: { pathname: '/' } };

  const message = "Don't have an account?";

  useEffect(() => {
    if (from.pathname === '/simulation') {
      setInfoSnackbar(true);
    }
  }, []);

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  const handleCloseInfoSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setInfoSnackbar(false);
  };

  const handleChange = (prop) => (event) => {
    setCredentials({ ...credentials, [prop]: event.target.value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const signinData = new SignInModel(credentials.email, credentials.password);

    await loginAPI('api/login', 'POST', signinData.toJSONString())
      .then((data) => {
        saveLoginData(data);
        setSnackbarMessage('Login successful!');
        setSnackbarSeverity('success');
        setOpenSnackbar(true);
        navigate(from.pathname);
      })
      .catch((error) => {
        const errorMessage = error.message || 'Login failed. Please try again!';
        setSnackbarMessage(errorMessage);
        setSnackbarSeverity('error');
        setOpenSnackbar(true);
      });
  };

  return (
    <Container
      component='main'
      maxWidth={false}
      sx={{
        width: '100vw',
        height: '100vh',
        bgcolor: '#ffeee1',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        p: '0px !important',
      }}
    >
      <Box
        sx={{
          width: '40vw',
          height: '100vh',
          display: 'flex',
          alignItems: 'flex-start',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <img src={'/images/Nasa.png'} style={{ ...nasaLogo }} />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            m: 10,
            mb: 30,
          }}
        >
          <Typography component='h1' variant='h3' sx={{ fontWeight: 'bold', mb: 6 }}>
            Welcome Back!
          </Typography>
          <Box component='form' onSubmit={handleSubmit} sx={{ mt: 1 }}>
            <TextField
              margin='normal'
              required
              fullWidth
              id='email'
              label='Email Address'
              name='email'
              autoComplete='email'
              autoFocus
              sx={{ bgcolor: '#fffaf7' }}
              value={credentials.email}
              onChange={handleChange('email')}
            />
            <TextField
              margin='normal'
              required
              fullWidth
              name='password'
              label='Password'
              type='password'
              id='password'
              autoComplete='current-password'
              sx={{ bgcolor: '#fffaf7' }}
              value={credentials.password}
              onChange={handleChange('password')}
            />
            <SignInButton type='submit' fullWidth variant='contained'>
              Sign In
            </SignInButton>
            <Typography variant='body1'>
              {message}
              <Link href='/signup' variant='body1' sx={{ fontWeight: 'bold', ml: 1 }}>
                Sign Up
              </Link>
            </Typography>
          </Box>
        </Box>
      </Box>
      <img src={imageUrls.auth_drone} width='70%' height='96%' style={{ margin: '1.2rem' }} />

      <Snackbar
        open={infoSnackbar}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        onClose={handleCloseInfoSnackbar}
      >
        <Alert onClose={handleCloseInfoSnackbar} severity='success' sx={{ width: '100%' }}>
          {`Don't worry, We've saved your simulation configuration details (if any). You can access them again once you log in!`}
        </Alert>
      </Snackbar>

      <Snackbar
        open={openSnackbar}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        style={{ marginBottom: '60px' }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default SignIn;
